import React, { useState, useEffect, useRef } from 'react';
import {
  IonPage,
  IonContent,
  IonModal,
  IonButton,
  IonIcon,
  IonToast,
  IonSpinner
} from '@ionic/react';
import { useIonRouter } from '@ionic/react';
import WaveSurfer from 'wavesurfer.js';

import { useSnapshot } from 'valtio';
import { close, playCircle, pauseCircle, share, heart, download, playBack, playForward, navigate } from 'ionicons/icons';
import { BASE_URL, configStore } from '../services/config.service';
import './Guide.scss';
import CustomHeader from '../components/CustomHeader';

const Guide: React.FC = () => {
  const { config, currentLocationId, pointsOfInterest, selections, instanceTitle } = useSnapshot(configStore);
  const router = useIonRouter();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [localLocationId, setLocalLocationId] = useState<string | null>(null);

  // Funzione per tornare alla home
  const goToHome = (locationId: string) => {
    localStorage.removeItem(`onboarding-${locationId}`);
    window.location.href = `/${locationId}`;
  };

  const initializeGuide = () => {
    console.log('Initializing Guide component');

    // Estrai l'ID della location dall'URL
    const path = window.location.pathname;
    const segments = path.split('/').filter(Boolean);
    if (segments.length > 0) {
      const locationId = segments[0];
      setLocalLocationId(locationId);

      // Aggiorna anche lo stato globale se necessario
      if (!currentLocationId) {
        configStore.currentLocationId = locationId;

        // Se config non è caricato, caricalo
        if (!config) {
          import('../services/config.service').then(module => {
            module.fetchConfig(locationId).catch(() => {
              // Se c'è un errore nel caricamento della config, torna alla home
              goToHome(locationId);
            });
          });
        }
      }
    } else {
      // Se non c'è un locationId nell'URL, reindirizza alla home
      window.location.href = '/';
    }
  }

  const isPoiAvailable = (poi: { meta: Record<string, string> }) => {
    return poi.meta?.available !== 'false';
  };

  // Estrai l'ID della location dall'URL all'inizio
  useEffect(() => {

    setTimeout(() => {
      initializeGuide();
    }, 10);
  }, []);

  // Se config non è disponibile, torna alla home
  useEffect(() => {
    const effectiveLocationId = currentLocationId || localLocationId;
    if (effectiveLocationId && !config) {
      // Aspetta un po' per vedere se config viene caricato
      const timer = setTimeout(() => {
        if (!config) {
          goToHome(effectiveLocationId);
        }
      }, 3000); // Aspetta 3 secondi

      return () => clearTimeout(timer);
    }
  }, [config, currentLocationId, localLocationId]);

  // Carica la guida quando abbiamo sia config che locationId
  useEffect(() => {
    const effectiveLocationId = currentLocationId || localLocationId;

    if (!effectiveLocationId || !config) {
      console.log('Missing required data:', { effectiveLocationId, config });
      return; // Aspetta che i dati siano disponibili
    }

    // Recupera le selezioni dal localStorage
    let effectiveSelections = selections;
    if (Object.keys(selections).length === 0) {

      const storedData = localStorage.getItem(`onboarding-${effectiveLocationId}`);
      if (storedData) {
        const parsed = JSON.parse(storedData);
        effectiveSelections = parsed.selections;
        // Aggiorna anche lo stato globale
        configStore.selections = parsed.selections;
      } else {
        // Se non ci sono selezioni né nello stato né nel localStorage, torna alla home
        goToHome(effectiveLocationId);
        return;
      }
      

     
    }

    const fetchGuide = async () => {
      try {
        setLoading(true);
        const payload = {
          instanceId: effectiveLocationId,
          selections: effectiveSelections
        };

        const response = await fetch(`${BASE_URL}/api/guide`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Errore nella richiesta');
        }

        const data = await response.json();

        // Salva i punti di interesse nello stato globale
        configStore.pointsOfInterest = data.resources.map((resource: any) => ({
          id: resource.id.toString(),
          title: resource.title,
          image: resource.featuredImage,
          audioUrl: resource.variant?.audioUrl || resource.audioUrl,
          soundtrackUrl: resource.variant?.soundtrackUrl || resource.soundtrackUrl,
          description: resource.description,
          duration: 120, // Valore di default temporaneo
          progress: 0,
          position: 0,
          meta: resource.meta || {}
        }));

        // Calcola le durate effettive degli audio
        calculateAudioDurations();

      } catch (err) {
        console.error('Errore durante il recupero della guida:', err);
        setError('Si è verificato un errore durante la creazione della guida');
      } finally {
        setLoading(false);
      }
    };

    fetchGuide();
  }, [config, currentLocationId, localLocationId, selections]);

  // Funzione per calcolare le durate degli audio
  const calculateAudioDurations = async () => {
    const pois = [...configStore.pointsOfInterest];
    
    // Crea un array di promesse per caricare tutti gli audio
    const durationPromises = pois.map(poi => {
      return new Promise<{id: string, duration: number}>((resolve) => {
        const audio = new Audio(poi.audioUrl);
        
        // Quando i metadati sono caricati, possiamo ottenere la durata
        audio.addEventListener('loadedmetadata', () => {
          resolve({
            id: poi.id,
            duration: Math.ceil(audio.duration)
          });
        });
        
        // In caso di errore, usa la durata di default
        audio.addEventListener('error', () => {
          resolve({
            id: poi.id,
            duration: 0 // Durata di default in secondi
          });
        });
        
        // Imposta un timeout nel caso in cui il caricamento sia troppo lento
        setTimeout(() => {
          resolve({
            id: poi.id,
            duration: 0 // Durata di default in secondi
          });
        }, 5000);
      });
    });
    
    try {
      // Attendi che tutte le durate siano calcolate
      const durations = await Promise.all(durationPromises);
      
      // Aggiorna lo stato globale con le durate calcolate
      configStore.pointsOfInterest = configStore.pointsOfInterest.map(poi => {
        const durationInfo = durations.find(d => d.id === poi.id);
        return durationInfo ? { ...poi, duration: durationInfo.duration } : poi;
      });
    } catch (error) {
      console.error('Errore nel calcolo delle durate audio:', error);
    }
  };

  const handleMainBackClick = () => {
    const effectiveLocationId = currentLocationId || localLocationId;
    goToHome(effectiveLocationId || '');
  };

  // Mostra un loader se config non è ancora disponibile
  if (!config) {
    return (
      <IonPage>
        <IonContent>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
            gap: '1rem'
          }}>
            <IonSpinner name="circular" />
            <p>Caricamento configurazione...</p>
            <IonButton onClick={handleMainBackClick}>
              Torna alla home
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  const handlePoiClick = (poiId: string) => {
    router.push(`/${currentLocationId}/poi/${poiId}`);
  };

  // Mock progress per ogni POI
  const progress: Record<string, number> = {

  };

  return (
    <IonPage>
      <CustomHeader
        title={instanceTitle || config.guide.headline || 'La tua guida'}
        showSettings={false}
        onBackClick={handleMainBackClick}
      />

      <IonContent>

        { config.guide.backgroundImage && config.welcome.backgroundImage && <div
          className="guide-welcome-image"
          style={{
            backgroundImage: `url(${config.welcome.backgroundImage})`,
            height: '190px'
          }}
        >
        </div>}

        <div className="poi-list">
          {pointsOfInterest.map((poi) => (
            <div key={poi.id} onClick={() => handlePoiClick(poi.id)}
                className={`poi-item ${!isPoiAvailable(poi) ? 'disabled-option' : ''}`}
            >
              <div
                className="poi-image"
                style={{ backgroundImage: `url(${poi.image})` }}
              />
              <div className="poi-content">
                <h3>{poi.title}</h3>
                <p className="duration">
                  {Math.floor(poi.duration / 60)} minuti
                  {poi.duration % 60 !== 0 && ` ${poi.duration % 60} secondi`}
                </p>
                <div className="progress-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${((poi.progress || 0) * 100)}%`,
                      backgroundColor: config.general.colors.primaryColor
                    }}
                  />
                </div>
              </div>
              <IonButton
                fill="clear"
                className="play-button"
                onClick={() => handlePoiClick(poi.id)}
              >
                <IonIcon
                  icon={playCircle}
                  slot="icon-only"
                  style={{
                    fontSize: '48px',
                    color: config.general.colors.primaryColor
                  }}
                />
              </IonButton>
            </div>
          ))}
        </div>

        { config.guide && config.guide.buttonText && (
          <IonButton
            expand="block"
            fill="outline"
            onClick={() => window.open(config.guide.buttonLink, '_blank')}
            className="w-full"
            style={{
              margin: '0 1.5rem'
            }}
          >
            {config.guide.buttonText}
          </IonButton>
        )}


        <IonToast
          isOpen={!!error}
          message={error || ''}
          duration={3000}
          onDidDismiss={() => setError(null)}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default Guide;
