import React, { useEffect, useRef, useState } from 'react';
import { 
  IonPage, 
  IonContent,
  IonButton,
  IonIcon,
  IonToast,
  useIonRouter
} from '@ionic/react';
import { useParams } from 'react-router';
import WaveSurfer from 'wavesurfer.js';
import { shareSocial, heart, download, playBack, playForward, playCircle, pauseCircle } from 'ionicons/icons';
import { useSnapshot } from 'valtio';
import { configStore } from '../services/config.service';
import CustomHeader from '../components/CustomHeader';
import './PoiDetail.scss';

const PoiDetail: React.FC = () => {
  const { config, selections, currentLocationId, pointsOfInterest, instanceTitle } = useSnapshot(configStore);
  const { id } = useParams<{ id: string }>();
  const [playingId, setPlayingId] = useState<string | null>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const soundtrackRef = useRef<HTMLAudioElement | null>(null);
  const router = useIonRouter();
  const poi = pointsOfInterest.find(p => p.id === id);
  const { colors } = config?.general || {};
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [isWaveformLoading, setIsWaveformLoading] = useState(true);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  if(!colors) return null;

  useEffect(() => {
    setIsComponentMounted(true);
    setIsWaveformLoading(true);
    
    // Inizializza il player della colonna sonora
    if (poi?.soundtrackUrl) {
      soundtrackRef.current = new Audio(poi.soundtrackUrl);
      soundtrackRef.current.volume = 0.02;
      soundtrackRef.current.loop = true;
    }
    
    return () => {
      setIsComponentMounted(false);
      // Ferma e pulisci la colonna sonora quando il componente viene smontato
      if (soundtrackRef.current) {
        soundtrackRef.current.pause();
        soundtrackRef.current = null;
      }
    };
  }, [poi?.soundtrackUrl]);

  useEffect(() => {
    if (!waveformRef.current || !poi || !colors?.primaryColor || !isComponentMounted) return;
    
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
      wavesurferRef.current = null;
    }
    
    const timer = setTimeout(() => {
      try {
        const audioUrl = poi.audioUrl;
        if (waveformRef.current) {
          wavesurferRef.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D1D1D1',
            progressColor: 'var(--ion-color-primary)',
            cursorColor: 'var(--ion-color-primary)',
            barWidth: 3,
            barGap: 2,
            height: 50,
            url: audioUrl,
            barRadius: 4,
            normalize: true,
          });

          wavesurferRef.current.on('ready', () => {
            if (isComponentMounted) {
              setIsWaveformLoading(false);
              
              // Salva la durata effettiva dell'audio
              const duration = Math.ceil(wavesurferRef.current?.getDuration() || 0);
              if (poi && duration > 0) {
                configStore.pointsOfInterest = configStore.pointsOfInterest.map(p => 
                  p.id === poi.id ? { ...p, duration } : p
                );
              }
              
              console.log('ready');
            }
          });
          
          // Aggiungi eventi per sincronizzare la colonna sonora
          wavesurferRef.current.on('play', () => {
            if (soundtrackRef.current) {
              soundtrackRef.current.play().catch(err => {
                console.error('Error playing soundtrack:', err);
              });
            }
          });
          
          wavesurferRef.current.on('pause', () => {
            if (soundtrackRef.current) {
              soundtrackRef.current.pause();
            }
          });
          
          
          // Aggiungi un intervallo per aggiornare la posizione ogni 2 secondi
          const progressInterval = setInterval(() => {
            if (wavesurferRef.current?.isPlaying() && poi) {
              const currentTime = Math.floor(wavesurferRef.current.getCurrentTime());
              const duration = Math.ceil(wavesurferRef.current.getDuration());
              
              // Aggiorna lo stato globale con la posizione attuale
              configStore.pointsOfInterest = configStore.pointsOfInterest.map(p => 
                p.id === poi.id ? { 
                  ...p, 
                  position: currentTime,
                  progress: duration > 0 ? currentTime / duration : 0
                } : p
              );
            }
          }, 2000);
          
          wavesurferRef.current.on('error', (err) => {
            console.error('WaveSurfer error:', err);
            setIsWaveformLoading(false);
          });
          
          // Pulisci l'intervallo quando il componente viene smontato
          return () => {
            clearInterval(progressInterval);
          };
        }
      } catch (error) {
        console.error('Error creating WaveSurfer:', error);
        setIsWaveformLoading(false);
      }
    }, 100);
    
    return () => {
      clearTimeout(timer);
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
    };
  }, [poi?.id, colors?.primaryColor, isComponentMounted]);

  const handlePlayPause = () => {
    if (wavesurferRef.current && poi) {
      wavesurferRef.current.playPause();
      const isPlaying = wavesurferRef.current.isPlaying();
      setPlayingId(isPlaying ? poi.id : null);
      
      // Sincronizza la colonna sonora
      if (soundtrackRef.current) {
        if (isPlaying) {
          soundtrackRef.current.play().catch(err => {
            console.error('Error playing soundtrack:', err);
          });
        } else {
          soundtrackRef.current.pause();
        }
      }
    }
  };

  const handleBack = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.pause();
      setPlayingId(null);
    }
    
    // Ferma la colonna sonora
    if (soundtrackRef.current) {
      soundtrackRef.current.pause();
    }
    
    router.push(`/${currentLocationId}/guide`);
  };

  const showToast = (message: string) => {
    setToastMessage(message);
  };

  if (!poi) return null;

  return (
    <IonPage>
      <CustomHeader 
        title={instanceTitle || config?.guide?.headline || 'La tua guida'}
        showSettings={false}
        onBackClick={handleBack}
      />
      <IonContent>
        <div className="poi-detail">
          <div className="image-container">
            <img 
              src={poi.image} 
              alt={poi.title} 
              className="poi-modal-image" 
            />
            {/* <div className="actions-overlay ion-color-primary-alpha">
              <IonButton fill="clear" onClick={() => showToast('Per share fai partire la condivisione di sistema')}>
                <IonIcon icon={shareSocial} slot="icon-only" style={{ color: colors.backgroundColor }} />
              </IonButton>
              <IonButton fill="clear" onClick={() => showToast('Aggiunto ai preferiti')}>
                <IonIcon icon={heart} slot="icon-only" style={{ color: colors.backgroundColor }} />
              </IonButton>
              <IonButton fill="clear" onClick={() => showToast('Download completato')}>
                <IonIcon icon={download} slot="icon-only" style={{ color: colors.backgroundColor }} />
              </IonButton>
            </div> */}
          </div>

          <div className="content-info">
            <h2 style={{ color: colors.primaryText }}>{poi.title}</h2>
            <p>{poi.description}</p>
          </div>

          <div className="audio-player">
            {isWaveformLoading && (
              <div className="skeleton-loader">Caricamento...</div>
            )}
            <div style={{ display: isWaveformLoading ? 'none' : 'block' }} ref={waveformRef} className="waveform" />
            <div className="controls">
              <IonButton 
                fill="clear" 
                className="control-button"
                onClick={() => wavesurferRef.current?.skip(-15)}
              >
                <IonIcon 
                  icon={playBack}
                  slot="icon-only" 
                  style={{ 
                    fontSize: '24px',
                    color: colors.primaryColor 
                  }} 
                />
              </IonButton>

              <IonButton 
                fill="clear" 
                className="play-button"
                onClick={handlePlayPause}
              >
                <IonIcon 
                  icon={playingId === poi.id ? pauseCircle : playCircle} 
                  slot="icon-only" 
                  style={{ 
                    fontSize: '48px',
                    color: colors.primaryColor 
                  }} 
                />
              </IonButton>

              <IonButton 
                fill="clear" 
                className="control-button"
                onClick={() => wavesurferRef.current?.skip(15)}
              >
                <IonIcon 
                  icon={playForward}
                  slot="icon-only" 
                  style={{ 
                    fontSize: '24px',
                    color: colors.primaryColor 
                  }} 
                />
              </IonButton>
            </div>
          </div>

          {config && config.guide && config.guide.buttonText && (
            <IonButton
              expand="block"
              fill="outline"
              onClick={() => window.open(config.guide.buttonLink, '_blank')}
              className="w-full"
              style={{
                margin: '0 1.5rem'
              }}

            >
              {config.guide.buttonText}
            </IonButton>
          )}
          
        </div>
        <IonToast
          isOpen={!!toastMessage}
          message={toastMessage || ''}
          duration={2000}
          onDidDismiss={() => setToastMessage(null)}
        />
      </IonContent>
    </IonPage>
  );
};

export default PoiDetail;
