import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import { useSnapshot } from 'valtio';

import { configStore, fetchConfig } from './services/config.service';
import { useThemeColors } from './hooks/useThemeColors';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Loading from './components/Loading';
import Welcome from './pages/Welcome';
import Onboarding from './pages/Onboarding';
import Guide from './pages/Guide';
import GeneratingGuidePage from './pages/GeneratingGuidePage';
import PoiDetail from './pages/PoiDetail';
import NotFound from './pages/NotFound';

setupIonicReact();

const App: React.FC = () => {
  const { loading, config, currentLocationId } = useSnapshot(configStore);
  useThemeColors();

  useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split('/').filter(Boolean);
    
    if (segments.length === 1) {
      const locationId = segments[0];
      // Fetch config solo se siamo su /:locationId esatto
      fetchConfig(locationId);
    } else {
      configStore.loading = false;
    }
    
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/">
            <NotFound />
          </Route>
          
          <Route exact path="/:locationId">
            <Welcome />
          </Route>
          
          <Route exact path="/:locationId/onboarding">
            <Onboarding />
          </Route>
          
          <Route exact path="/:locationId/guide">
            <Guide />
          </Route>
          
          <Route exact path="/:locationId/generating">
            <GeneratingGuidePage />
          </Route>
          
          <Route path="/:locationId/poi/:id" component={PoiDetail} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
