import React, { useState, useEffect } from 'react';
import { 
  IonPage, 
  IonContent, 
  IonButton, 
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
  IonList
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { configStore } from '../services/config.service';
import './Onboarding.scss';
import { useIonRouter } from '@ionic/react';

export interface StoredSelections {
  instanceId: string;
  selections: Record<string, string | string[]>;
  instanceTitle?: string;
}

const isOptionAvailable = (option: { meta: Record<string, string> }) => {
  return option.meta?.available !== 'false';
};

const Onboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selections, setSelections] = useState<Record<string, string | string[]>>({});
  const ionRouter = useIonRouter();
  const { config, currentLocationId } = useSnapshot(configStore);
  const { colors } = config?.general || {};
  if (!config || !colors) return null;

  const { steps } = config.onboarding;
  const currentStepData = steps[currentStep];

  useEffect(() => {
    // Inizializza le selections con i dati dal configStore
    setSelections(configStore.selections || {});
  }, []);

  useEffect(() => {
    // Verifica se lo step corrente è già presente nelle selections
    const currentStepId = currentStepData?.stepId;
    if (currentStepId && selections[currentStepId]) {
      if (currentStep < steps.length - 1) {
        // Se non è l'ultimo step, passa al successivo
        setCurrentStep(currentStep + 1);
      } else {
        // Se è l'ultimo step, vai alla pagina generating
        // Recupera l'instanceTitle dal localStorage se esiste
        const storedData = localStorage.getItem(`onboarding-${currentLocationId}`);
        const existingData = storedData ? JSON.parse(storedData) as StoredSelections : null;
        
        const dataToStore: StoredSelections = {
          instanceId: currentLocationId || '',
          selections,
          instanceTitle: existingData?.instanceTitle || configStore.config?.general?.title
        };

        // Verifico se lo step corrente non è di tipo multiple
        if (currentStepData.selectionType !== 'multiple') {
          localStorage.setItem(`onboarding-${currentLocationId}`, JSON.stringify(dataToStore));
          ionRouter.push(`/${currentLocationId}/generating`, 'forward', 'push');
        }
      }
    }
  }, [currentStep, selections, steps.length]);

  const handleSelection = (key: string, value: string) => {
    const stepId = currentStepData.stepId;

    // Recupera l'instanceTitle esistente dal localStorage
    const storedData = localStorage.getItem(`onboarding-${currentLocationId}`);
    const existingData = storedData ? JSON.parse(storedData) as StoredSelections : null;
    
    // Aggiorna instanceTitle solo se siamo nello step product-card
    let instanceTitle = existingData?.instanceTitle || configStore.config?.general?.title;
    if(currentStepData.contentType === 'product-card') {
      const productCard = currentStepData.options.find(option => option.value === value);
      instanceTitle = productCard?.headline || instanceTitle;
    }
    
    let newValue;
    if (currentStepData.selectionType === 'multiple') {
      const currentSelections = (selections[stepId] as string[]) || [];
      
      if (currentSelections.includes(value)) {
        newValue = currentSelections.filter(item => item !== value);
      } else {
        newValue = [...currentSelections, value];
      }
    } else {
      newValue = value;
    }
    
    const newSelections = {
      ...selections,
      [stepId]: newValue
    };
    
    setSelections(newSelections);
    
    configStore.selections = {
      ...configStore.selections,
      [stepId]: newValue
    };

    configStore.instanceTitle = instanceTitle || '';
    
    const dataToStore: StoredSelections = {
      instanceId: currentLocationId || '',
      selections: newSelections,
      instanceTitle: instanceTitle // Ora mantiene il valore precedente se non siamo in product-card
    };

    localStorage.setItem(`onboarding-${currentLocationId}`, JSON.stringify(dataToStore));
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      ionRouter.push(`/${currentLocationId}/generating`, 'forward', 'push');
    }
  };

  const isNextDisabled = () => {
    const stepId = currentStepData.stepId;
    const currentSelection = selections[stepId];
    if (currentStepData.selectionType === 'multiple') {
      return !currentSelection || (currentSelection as string[]).length === 0;
    } else {
      return !currentSelection;
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className='onboarding-page'>
          <div className="onboarding-container">
              {/* <div className="step-indicators">
                {steps.map((_, index) => (
                  <div 
                    key={index} 
                    className={`step-dot ${index === currentStep ? 'active' : ''}`} 
                  />
                ))}
              </div> */}

              <h2>{currentStepData.headline}</h2>
              <p>{currentStepData.description}</p>
            </div>

            <IonList>
              {currentStepData.selectionType === 'multiple' ? (
                currentStepData.options.map((option, index) => (
                  <IonItem 
                    key={index} 
                    lines='none'
                    className={!isOptionAvailable(option) ? 'disabled-option' : ''}
                  >
                    <IonCheckbox
                      slot="start"
                      value={option.value}
                      checked={(selections[currentStepData.stepId] as string[] || []).includes(option.value)}
                      onIonChange={() => handleSelection(currentStepData.stepId, option.value)}
                      disabled={!isOptionAvailable(option)}
                    />
                    <IonLabel className={`onboarding-label `}>
                      {currentStepData.contentType === 'product-card' ? (
                        <ProductCard option={option} />
                      ) : (
                        <>
                          <h2>{option.emoji} {option.headline}</h2>
                          <p>{option.description}</p>
                        </>
                      )}
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonRadioGroup 
                  value={selections[currentStepData.stepId] as string}
                  onIonChange={e => handleSelection(currentStepData.stepId, e.detail.value)}
                >
                  {currentStepData.options.map((option, index) => (
                    <IonItem 
                      key={index} 
                      lines='none'
                      className={!isOptionAvailable(option) ? 'disabled-option' : ''}
                    >
                      <IonRadio 
                        slot="start" 
                        value={option.value} 
                        disabled={!isOptionAvailable(option)}
                      />
                      <IonLabel className={`onboarding-label `}>
                        {currentStepData.contentType === 'product-card' ? (
                          <ProductCard option={option} />
                        ) : (
                          <>
                            <h2>{option.emoji} {option.headline}</h2>
                            <p>{option.description}</p>
                          </>
                        )}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonRadioGroup>
              )}
            </IonList>

            <div className='onboarding-container'>
              <div className="button-container">
                {currentStep > 0 ? (
                  <IonButton 
                    className='back'
                    fill="clear"
                    onClick={() => {
                      const previousStep = steps[currentStep - 1]
                      const previousStepId = previousStep?.stepId;

                      const newSelections = { ...selections };
                      delete newSelections[previousStepId];
                      setSelections(newSelections);
                      
                      setCurrentStep(currentStep - 1)
                    }}
                  >
                    Indietro
                  </IonButton>
                ) : (
                  <div></div> // Elemento vuoto per mantenere il layout flex
                )}
                
                <IonButton 
                  className='next'
                  onClick={handleNext}
                  disabled={isNextDisabled()}
                >
                  {currentStep < steps.length - 1 ? 'Avanti' : 'Fine'}
                </IonButton>
              </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding; 



const ProductCard: React.FC<{ option: any }> = ({ option }) => {
  return (
    <div className='product-card'>
      <img src={option.image} alt={option.headline} />
      <div className='product-card-content'>
        <h2>{option.emoji} {option.headline}</h2>
        <p>{option.description}</p>
      </div>
    </div>
  );
};