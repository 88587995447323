import { proxy } from 'valtio';
import { devtools } from 'valtio/utils'
import { soundTracks } from '../configs/locations';

// Usa la variabile d'ambiente o un valore di fallback
export const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost';

export interface Config {
  general: {
    title: string;
    colors: {
      primaryText: string;
      secondaryText: string;
      backgroundColor: string;
      primaryColor: string;
      secondaryColor: string;
    };
    fonts: {
      title: string;
      body: string;
    };
    elements: {
      borderRadius: string;
      buttons: {
        primary: {
          backgroundColor: string;
          backgroundColorHover: string;
          textColor: string;
          textColorHover: string;
        };
        secondary: {
          backgroundColor: string;
          backgroundColorHover: string;
          textColor: string;
          textColorHover: string;
        };
      };
    };
    availableLanguages: string;
  };
  welcome: {
    backgroundImage: string;
    headline: string;
    description: string;
    detailedText: string;
  };
  generating: {
    headline: string;
    description: string;
  };
  guide: {
    headline: string;
    buttonText: string;
    buttonLink: string;
    backgroundImage: boolean;
  };
  onboarding: {
    steps: Array<{
      stepId: string;
      headline: string;
      description: string;
      selectionType: 'single' | 'multiple';
      contentType?: 'product-card' | 'text';
      options: Array<{
        headline: string;
        emoji: string;
        image?: string;
        description: string;
        value: string;
        meta: any;
      }>;
    }>;
  };
}


export interface PointOfInterest {
  id: string;
  title: string;
  image: string;
  audioUrl: string;
  soundtrackUrl: string;
  description: string;
  duration: number;
  progress?: number;
  meta: Record<string, string>;
  buttonText?: string;
  buttonLink?: string;
}

interface ConfigStore {
  config: Config | null;
  loading: boolean;
  currentLocationId: string | null;
  selections: Record<string, string | string[]>;
  pointsOfInterest: PointOfInterest[];
  instanceTitle: string;
}

export const configStore = proxy<ConfigStore>({
  config: null,
  loading: true,
  currentLocationId: null,
  selections: {},
  pointsOfInterest: [],
  instanceTitle: ''
});

const unsub = devtools(configStore, { name: 'configStore', enabled: true })

export const fetchConfig = async (locationId: string): Promise<void> => {
  configStore.loading = true;

  try {
    const response = await fetch(`${BASE_URL}/api/instance/${locationId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

  

    configStore.config = data.config;
    configStore.currentLocationId = locationId;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  } finally {
    configStore.loading = false;
  }
};
