import React from 'react';
import { IonContent, IonPage, IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { useSnapshot } from 'valtio';
import { configStore } from '../services/config.service';
import './GeneratingGuidePage.scss';

const GeneratingGuidePage: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const ionRouter = useIonRouter();
  const { config, currentLocationId } = useSnapshot(configStore);

  if (!config) return null;
  const { colors } = config.general;

  useEffect(() => {
    if (!isAnimating) return;

    let isComponentMounted = true;
    const startTime = Date.now();
    const duration = 5000;

    const updateProgress = () => {
      if (!isComponentMounted) return;

      const elapsed = Date.now() - startTime;
      const newProgress = Math.min((elapsed / duration) * 100, 100);
      
      if (newProgress < 100) {
        setProgress(newProgress);
        requestAnimationFrame(updateProgress);
      } else {
        setProgress(100);
        if (isComponentMounted && document.location.pathname === `/${currentLocationId}/generating`) {
          ionRouter.push(`/${currentLocationId}/guide`, 'forward', 'push');
        }
      }
    };

    requestAnimationFrame(updateProgress);

    return () => {
      isComponentMounted = false;
    };
  }, [ionRouter, isAnimating]);

  return (
    <IonPage>
      <IonContent>
        <div className="generating-container">
          <h1>{config.generating && config.generating.headline ? config.generating.headline : 'Stiamo creando la tua guida personalizzata!'}</h1>
          <p>{config.generating && config.generating.description ? config.generating.description : 'Un momento di attesa e presto avrai un percorso unico, pensato apposta per te.'}</p>
          
          <div className="progress-circle-container">
            <div className="progress-text" style={{ color: colors.primaryColor }}>
              {Math.round(progress)}%
            </div>
            <svg className="progress-circle" viewBox="0 0 100 100">
              <circle 
                className="progress-circle-bg"
                cx="50" 
                cy="50" 
                r="45"
              />
              <circle 
                className="progress-circle-path"
                cx="50" 
                cy="50" 
                r="45"
                style={{
                  strokeDasharray: `${progress * 2.827}, 282.7`,
                  stroke: colors.primaryColor
                }}
              />
            </svg>
          </div>
          
          <IonButton 
            fill="clear" 
            onClick={() => {
              configStore.selections = {};
              localStorage.removeItem(`onboarding-${currentLocationId}`);
              ionRouter.push(`/${currentLocationId}`, 'back');
            }}
          >
            Annulla
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GeneratingGuidePage; 