import React, { useState, useEffect } from 'react';
import { 
  IonPage, 
  IonContent, 
  IonButton, 
  IonModal,
  useIonRouter,
  IonSelect,
  IonSelectOption,
  IonToast
} from '@ionic/react';
import { useSnapshot } from 'valtio';
import { close } from 'ionicons/icons';
import { configStore } from '../services/config.service';
import './Welcome.scss';
import CustomHeader from '../components/CustomHeader';
import { StoredSelections } from './Onboarding';
import { log } from 'console';

const languageMap: Record<string, { name: string }> = {
  en: { name: "🇬🇧 English" },
  it: { name: "🇮🇹 Italiano" },
  de: { name: "🇩🇪 Deutsch" },
  es: { name: "🇪🇸 Español" },
  fr: { name: "🇫🇷 Français" },
  nl: { name: "🇳🇱 Nederlands" },
  pl: { name: "🇵🇱 Polski" },
  pt: { name: "🇵🇹 Português" },
  ro: { name: "🇷🇴 Română" },
  sv: { name: "🇸🇪 Svenska" },
  tr: { name: "🇹🇷 Türkçe" }
};

const Welcome: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useIonRouter();
  const { config, currentLocationId } = useSnapshot(configStore);
  const [showToast, setShowToast] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('it');

  if (!config || !currentLocationId) return null;

  const { colors } = config.general;
  const availableLanguages = config.general.availableLanguages.split(',');

  const handleLanguageChange = (value: string) => {
    if (!availableLanguages.includes(value)) {
      setShowToast(true);
      setSelectedLanguage('it');
    } else {
      setSelectedLanguage(value);
    }
  };

  useEffect(() => {
    if (currentLocationId) {
      // Verifico se ci sono dati in query params
      const queryParams = new URLSearchParams(window.location.search);
      const profilingData = Object.fromEntries(queryParams.entries());
      if (profilingData && Object.keys(profilingData).length > 0) {
        
        configStore.selections = profilingData;
      

        // Verifico se tutti gli step in config.onboarding sono presenti nel profilingData
        const allStepsPresent = config.onboarding.steps.every((step: any) => profilingData[step.id]);
        if (allStepsPresent) {
          router.push(`/${currentLocationId}/guide`, 'forward', 'push');
        } else {
          // Se non sono presenti tutti gli step, porto all'onboarding
          router.push(`/${currentLocationId}/onboarding`, 'forward', 'push');
        }

      } else {

        const storedData = localStorage.getItem(`onboarding-${currentLocationId}`);
        if (storedData) {
          const parsed = JSON.parse(storedData) as StoredSelections;
          // Ripristina le selezioni salvate
          configStore.selections = parsed.selections;
          configStore.instanceTitle = parsed.instanceTitle || '';
          // Vai direttamente alla guida
          router.push(`/${currentLocationId}/guide`, 'forward', 'push');
        }
      }
    }
  }, [currentLocationId]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={`Questa lingua sarà supportata a breve.`}
          duration={2000}
        />
        <div className="language-selector" style={{ backgroundColor: colors.backgroundColor }}>
          <IonSelect
            interface="popover"
            placeholder="Seleziona Lingua"
            value={selectedLanguage}
            onIonChange={(e) => handleLanguageChange(e.detail.value)}
            style={{ color: colors.primaryText }}
          >
            {availableLanguages.map((langCode) => (
              <IonSelectOption key={langCode} value={langCode}>
                {languageMap[langCode as keyof typeof languageMap]?.name || langCode}
              </IonSelectOption>
            ))}
          </IonSelect>
        </div>
        <div className="welcome-container">
          <div 
            className="welcome-background__image-wrapper"
            style={{
              background: `linear-gradient(
                to top,
                ${colors.backgroundColor} 0%,
                transparent 30%,
                transparent 100%
              )`
            }}
          >
            <img src={config.welcome.backgroundImage} alt="Welcome" />

          </div>
          <div className="welcome-overlay" 
               
          />
          <div className="welcome-content">
            <h1 style={{ color: colors.primaryText }}>{config.welcome.headline}</h1>
            <p style={{ color: colors.primaryText }}>{config.welcome.description}</p>
            
            <div className="button-container">
              <IonButton 
                expand="block"
                fill="solid"
                onClick={() => router.push(`/${currentLocationId}/onboarding`, 'forward')}
                color="primary"
              >
                Inizia
              </IonButton>

             {/*  <IonButton 
                expand="block"
                fill='clear'
                onClick={() => setIsOpen(true)}
                color="primary"
              >
                Scopri di più
              </IonButton> */}
            </div>
          </div>
        </div>

        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
          <IonPage>
            <CustomHeader 
              title={"Dettagli"} 
              onBackClick={() => setIsOpen(false)}
              showSettings={false}
            />
            <IonContent>
              <div className="modal-content" style={{
                paddingLeft: '1.5rem', 
                paddingRight: '1.5rem',
                color: colors.primaryText
              }}>
                <p>{config.welcome.detailedText}</p>
              </div>
            </IonContent>
          </IonPage>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Welcome; 