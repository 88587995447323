import React from 'react';
import { IonSpinner } from '@ionic/react';

const Loading: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw'
    }}>
      <IonSpinner name="circular" />
    </div>
  );
};

export default Loading; 