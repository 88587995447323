import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { configStore } from '../services/config.service';

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result 
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null;
};

const shadeColor = (color: string, percent: number) => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = (num >> 8 & 0x00FF) + amt;
  const B = (num & 0x0000FF) + amt;

  return '#' + (0x1000000 + 
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + 
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + 
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  ).toString(16).slice(1);
};

export const useThemeColors = () => {
  const { config } = useSnapshot(configStore);

  useEffect(() => {
    if (config?.general.colors) {
      const { colors } = config.general;
      
      // Primary Color
      document.documentElement.style.setProperty('--ion-color-primary', colors.primaryColor);
      document.documentElement.style.setProperty('--ion-color-primary-rgb', hexToRgb(colors.primaryColor));
      document.documentElement.style.setProperty('--ion-color-primary-shade', shadeColor(colors.primaryColor, -10));
      document.documentElement.style.setProperty('--ion-color-primary-tint', shadeColor(colors.primaryColor, 10));
      document.documentElement.style.setProperty('--app-color-primary', colors.primaryColor);

      

      // Secondary Color
      document.documentElement.style.setProperty('--ion-color-secondary', colors.secondaryColor);
      document.documentElement.style.setProperty('--ion-color-secondary-rgb', hexToRgb(colors.secondaryColor));
      document.documentElement.style.setProperty('--ion-color-secondary-shade', shadeColor(colors.secondaryColor, -10));
      document.documentElement.style.setProperty('--ion-color-secondary-tint', shadeColor(colors.secondaryColor, 10));
      document.documentElement.style.setProperty('--app-color-secondary', colors.secondaryColor);
      

      // Background
      document.documentElement.style.setProperty('--ion-background-color', colors.backgroundColor);
      document.documentElement.style.setProperty('--ion-background-color-rgb', hexToRgb(colors.backgroundColor));

      // Text
      document.documentElement.style.setProperty('--ion-text-color', colors.primaryText);
      document.documentElement.style.setProperty('--ion-text-color-rgb', hexToRgb(colors.primaryText));
      document.documentElement.style.setProperty('--app-primary-text-color', colors.primaryText || '#000000');
      document.documentElement.style.setProperty('--app-secondary-text-color', colors.secondaryText || '#000000');

      // Fonts 
      document.documentElement.style.setProperty('--app-font-titles-family', config?.general?.fonts?.title || 'Roboto');
      document.documentElement.style.setProperty('--app-font-body-family', config?.general?.fonts?.body || 'Roboto');

      // Elements
      document.documentElement.style.setProperty('--app-border-radius', config?.general?.elements?.borderRadius || '10px');

      // Buttons
      document.documentElement.style.setProperty('--app-button-color-primary', config?.general?.elements?.buttons?.primary?.backgroundColor || '#000000');
      document.documentElement.style.setProperty('--app-button-color-primary-hover', config?.general?.elements?.buttons?.primary?.backgroundColorHover || '#000000');
      document.documentElement.style.setProperty('--app-button-color-primary-text', config?.general?.elements?.buttons?.primary?.textColor || '#000000');
      document.documentElement.style.setProperty('--app-button-color-primary-text-hover', config?.general?.elements?.buttons?.primary?.textColorHover || '#000000');

      document.documentElement.style.setProperty('--app-button-color-secondary', config?.general?.elements?.buttons?.secondary?.backgroundColor || '#000000');
      document.documentElement.style.setProperty('--app-button-color-secondary-hover', config?.general?.elements?.buttons?.secondary?.backgroundColorHover || '#000000');
      document.documentElement.style.setProperty('--app-button-color-secondary-text', config?.general?.elements?.buttons?.secondary?.textColor || '#000000');
      document.documentElement.style.setProperty('--app-button-color-secondary-text-hover', config?.general?.elements?.buttons?.secondary?.textColorHover || '#000000');
      

      // Fonnts
      document.documentElement.style.setProperty('--app-font-titles-family', config?.general?.fonts?.title || 'Roboto');
      document.documentElement.style.setProperty('--app-font-body-family', config?.general?.fonts?.body || 'Roboto');
      document.documentElement.style.setProperty('--ion-font-family', config?.general?.fonts?.body || 'Roboto');

      
    }
  }, [config]);
}; 