import React from 'react';
import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { chevronBack, ellipsisHorizontal, arrowBack, logoApple, logoAndroid } from 'ionicons/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { configStore } from '../services/config.service';
import './CustomHeader.scss';
import RoundButton from './RoundButton';

interface CustomHeaderProps {
  title: string;
  showBack?: boolean;
  showSettings?: boolean;
  onBackClick?: () => void;
  onSettingsClick?: () => void;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  title,
  showBack = true,
  showSettings = true,
  onBackClick,
  onSettingsClick
}) => {
  const location = useLocation();
  const history = useHistory();
  const { config } = useSnapshot(configStore);
  const ionRouter = useIonRouter();

  if (!config) return null;

  const { colors } = config.general;

  const handleBack = () => {
   onBackClick?.();
  };

  return (
    <div className="custom-header ion-background-color">
      {showBack && (
        <RoundButton 
          icon={arrowBack} 
          onClick={handleBack}
        />
      )}
      
      <h1 style={{ color: colors?.primaryText }}>{title}</h1>
      
      {showSettings && (
        <IonButton 
          className="settings-button"
          style={{ '--color': colors?.primaryColor }}
          fill='clear'
          onClick={onSettingsClick}
        >
          <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
        </IonButton>
      )}
    </div>
  );
};

export default CustomHeader; 