import React from 'react';
import { IonIcon, IonRippleEffect } from '@ionic/react';
import './RoundButton.scss';

interface RoundButtonProps {
  icon: string;
  onClick: () => void;
}

const RoundButton: React.FC<RoundButtonProps> = ({ icon, onClick }) => {
  return (
    <div 
      className="round-button ion-activatable ripple-parent"
      onClick={onClick}
    >
      <IonIcon icon={icon} />
      <IonRippleEffect />
    </div>
  );
};

export default RoundButton; 