import React from 'react';
import { 
  IonPage, 
  IonContent, 
  IonButton,
  useIonRouter
} from '@ionic/react';
import './NotFound.scss';

const NotFound: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="not-found-container">
          <div className="not-found-content">
            <h1>404</h1>
            <h2>Pagina non trovata</h2>
            <p>La pagina che stai cercando non esiste o non è disponibile.</p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound; 